<template lang="pug">
    .root.text-right
        .jumbotron.pull-right()
            .container-fluid.text-right
                .label
                    h2.moto-text {{page.title}} - Buy Amazon Inc.
        .container.text-right
            .content.mt-5.gap-bottom(v-html="page.content")
</template>


<style lang="scss">
    .jumbotron {
        background-color: #091a38 !important;
        padding: 2rem 1rem !important;
        border-radius: 0 !important;

        h2.moto-text {
            font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
            font-weight: 600;
            font-size: 39px;
            color: #f9fbfd;

            span {
                color: orange;
            }
        }

        @media only screen and (max-width: 767px) {
            padding-top: 5rem !important;
        }
    }

    .container {
        @media only screen and (max-width: 767px) {
            .gap-bottom {
                padding-bottom: 6rem;
                width: 100% !important;
            }
        }
    }
</style>


<script lang="js">
    export default {
        computed: {
            page() {
                return this.$store.state.ABOUT_PAGE
            }
        }
    }
</script>
